<!-- src/components/SiteDisclaimer.vue -->
<template>
  <div class="max-w-4xl mx-auto my-8 p-6 bg-white rounded-lg shadow-lg text-gray-800">
    <h1 class="text-2xl font-bold mb-6 text-center text-blue-600">Haftungsausschluss</h1>
    <p class="mb-6 text-justify">
      Der Kritis Check dient ausschließlich zu Informationszwecken und erhebt keinen Anspruch auf Vollständigkeit oder Richtigkeit. Die Ergebnisse des Kritis Checks basieren auf den vom Benutzer bereitgestellten Informationen und werden automatisch generiert. Trotz sorgfältiger Prüfung können wir keine Haftung für die Aktualität, Richtigkeit und Vollständigkeit der Ergebnisse übernehmen.
    </p>
    <p class="mb-6 text-justify">
      Der Kritis Check basiert auf den Vorschriften des BSI-Gesetzes (BSIG) vom 23. Juni 2021 und der BSI-Kritisverordnung (KritisV) vom 22.04.2016 zuletzt geändert durch Art. 1 V v. 29.11.2023.
    </p>
    <p class="mb-6 text-justify">
      Datum der letzten Überarbeitung: Juni 2024.
    </p>
  </div>
</template>

<script>
export default {
  name: 'SiteDisclaimer'
}
</script>

<style scoped>
/* Custom class for justified text */

</style>
