<template>
  <div id="app" class="m-0 p-0">
    <HeaderComponent />

    <!-- The <router-view> component renders the matched component for the current route -->
    <router-view />
    <CookieConsent />
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue'
import FooterComponent from './components/FooterComponent.vue'
import CookieConsent from './components/CookieConsent.vue';
export default {
  name: 'App',
  components: {
    HeaderComponent,
    FooterComponent,
    CookieConsent
  },
  metaInfo: {
    title: 'kritischeck.de',
    meta: [
      { name: 'description', content: 'kritischeck.de' },
      { property: 'og:title', content: 'kritischeck.de' },    
      { property: 'og:url', content: 'https://www.kritischeck.de' },
      { property: 'og:image', content: "../assets/Einfach-IT-Sicher-logo-only.png" }
      
    ],
  },
};

</script>

<style scoped>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
