// Import necessary functions and components from Vue Router and the QuizView component
import { createRouter, createWebHistory } from 'vue-router';
import QuizView from '../views/QuizView.vue';
import PrivacyPolicy from '../components/PrivacyPolicy.vue';
import SiteDisclaimer from '../components/SiteDisclaimer.vue';
import StartSeite from '../components/StartSeite.vue';
import ImpressumSeite from '../components/ImpressumSeite.vue';
import KontaktSeite from '../components/KontaktSeite.vue';
import ConsentTest from '../components/CookieConsent.vue';
import CookiePolicy from '../components/CookiePolicy.vue';
import ReportGesendet from '../components/ReportGesendet.vue';
import NewsletterConfirmation from '../components/NewsletterConfirmation.vue';
import NewsletterConfirmed from '../components/NewsletterConfirmed.vue';
import NewsLetter from '../components/NewsLetter.vue';


// Define the routes for the application
const routes = [
  {
    path: '/', // The root path of the application
    name: 'startseite', // The name of the route
    component: StartSeite // The component to be rendered when the route is accessed
  },
  { path: '/quiz', component: QuizView },
  { path: '/privacy-policy', component: PrivacyPolicy },
  { path: '/disclaimer', component: SiteDisclaimer },
  { path: '/startseite', component: StartSeite },
  { path: '/impressum', component: ImpressumSeite },
  { path: '/kontakt', component: KontaktSeite },
  { path : '/consent', component: ConsentTest},
  { path: '/cookie-policy', component: CookiePolicy},
  {path: '/ReportGesendet', component: ReportGesendet},
  {path: '/NewsletterConfirmation', component: NewsletterConfirmation},
  {path: '/NewsletterConfirmed', component: NewsletterConfirmed},
  {path: '/NewsLetter', component: NewsLetter}
 
  // Add more routes here as needed
];

// Create a router instance with the specified history mode and routes
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), // Use HTML5 history mode for navigation
  routes // The routes defined above
});

// Set the default title for all routes
const defaultTitle = 'Kritis Check';

router.beforeEach((to, from, next) => {
  document.title = defaultTitle;
  next();
});

// Export the router instance for use in the main application
export default router;


