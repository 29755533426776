<template>
  <div class="min-h-screen flex items-center justify-center bg-gray-100 p-4">
    <div class="bg-white p-8 rounded shadow-md w-full max-w-3xl mx-auto">
      
      <p class="text-lg text-gray-700 mb-6">Bleiben Sie auf dem Laufenden mit unserem <span class="font-bold text-blue-500">Kritis Check Newsletter</span> und erhalten Sie die neuesten Updates und Informationen zum Thema Kritis!</p>
      <ul class="list-disc list-inside space-y-2 pl-4 mb-6 text-left text-xl">
        <li class="text-gray-700 font-semibold">Bleiben Sie auf dem laufenden bezüglich Kritis</li>
        <li class="text-gray-700 font-semibold">Detailierte Erklärung der wichtigsten Sicherheitsmaßnahmen</li>
        <li class="text-gray-700 font-semibold">Unterstützung bei der Kritis Umsetzung</li>
      </ul>
      <hr class="mb-6">
      <form @submit.prevent="submitForm" class="space-y-6">
        <div>
          <label for="email" class="block text-gray-700 font-bold mb-2">Ihre E-Mail Addresse:</label>
          <input type="email" id="email" v-model="newsletterEmail" required class="p-3 border rounded w-full" placeholder="Pflichtfeld">
        </div>
        <div class="bg-gray-50 p-4 rounded mb-6">
  <label class="inline-flex items-center cursor-pointer">
    <div class="flex items-center space-x-4">
      <div class="w-20 h-6 flex-shrink-0"> <!-- Fixed width container for the slider -->
        <input type="checkbox" v-model="newsletterConsent" class="sr-only peer" required>
        <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-400 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
      </div>
      <div class="flex-grow">
        <span class="ms-3 text-sm font-medium text-gray-900">
          Ja, ich möchte den <span class="font-bold text-blue-500">Kritis Check Newsletter</span> erhalten und über Neuigkeiten und Updates bezüglich der Kritis Thematik informiert werden.
        </span>
      </div>
    </div>
  </label>
</div>


        <a href="/privacy-policy" class="text-blue-500 hover:text-blue-700 block mb-4">Datenschutzrichtlinie</a>
        <div>
          <label for="feedback" class="block text-gray-700 font-bold mb-2">Haben Sie Feedback über die Benutzererfahrung des Kritis Checks, das Sie mit uns teilen möchten?:</label>
          <textarea id="feedback" v-model="userFeedback" class="p-3 border rounded w-full" placeholder="Optional"></textarea>
        </div>
        <button type="submit" class="w-full p-3 bg-blue-500 text-white rounded hover:bg-blue-700 text-xl">Kritis Check Newsletter Abonnieren!</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'NewsLetter',
  data() {
    return {
      companyName: '',
      contactFirstName: '',
      contactLastName: '',
      contactRole: '',
      newsletterEmail: '',
      newsletterConsent: false,
      securityNewsletterConsent: false,
      emailConsent: false,
      userFeedback: '',
      message_sent: false,
      randomString: null,
      apiBaseUrl3: process.env.VUE_APP_API_BASE_URL3
      
    };
  },
  created() {
    // Access the query parameter
    this.randomString = this.$route.query.randomstring;
    console.log('Random String: ' + this.randomString);
  },
  methods: {
    async submitForm() {
      try {
  const response = await axios.get(`${this.apiBaseUrl3}/newsletter`, {
    params: {
      randomstring: this.randomString,
      newsletterEmail: this.newsletterEmail,
      newsletterConsent: this.newsletterConsent,
      userFeedback: this.userFeedback,
    },   
   
   

  });
   //if response code is 200 route to /startseite
  if (response.status !== 200) {
    throw new Error('Network response was not ok');
  }
  console.log('Nachricht erfolgreich gesendet!');
  this.message_sent = true;
  this.$router.push('/NewsletterConfirmation');

  

  
  
} catch (error) {
  alert('Es gab ein Problem beim Senden der Nachricht: ' + error.message);
}
    },
  },
};
</script>

<style scoped>
.min-h-screen {
  min-height: 100vh;
}
</style>
