<template>
  
  <div class="m-0 p-0">
    
  <div class="container mx-auto p-4">
    <div class="progress-bar h-6 bg-gray-300 rounded-full overflow-hidden my-6">
      <div class="progress-bar__inner bg-blue-500 h-full" :style="{ width: `${progress}%` }"></div>
    </div>

    <div v-if="endQuiz" class="text-center my-8">
      <h2 class="text-3xl font-bold text-blue-500">Kritis Check abgeschlossen!</h2>
      <!-- User einen call to action geben ihre email inklusive spamfolder zu überprüfen für die bestätigungs email-->
      <p class="text-lg text-gray-700 p-4">Vielen Dank für Ihre Teilnahme am Kritis Check. Bitte überprüfen Sie Ihre Email, inklusive ihres Spam-Ordner für die Bestätigungs-E-Mail.</p>

    </div>
    <QuestionCard
      v-else-if="currentQuestion < questions.length"
      :question="questions[currentQuestion].text"
      :options="getOptionsForCurrentQuestion()"
      :type="questions[currentQuestion].type"
      :multiple="questions[currentQuestion].multiple"
      :currentQuestion="currentQuestion"
      :selectedSectors="selectedSectors"
      :selectionArray="selectionArray"
      :availableServices="availableServices"
      :quizcompleted="quizcompleted"
      @answer="handleAnswer"
    />
  </div>
</div>


    
  
</template>


<script>

import QuestionCard from '../components/QuestionCard.vue';
import axios from 'axios';

// Load environment variables .
const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
const apiBaseUrl2 = process.env.VUE_APP_API_BASE_URL2;
const apiBaseUrl3 = process.env.VUE_APP_API_BASE_URL3;

// Create an instance of axios with default settings
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL3,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true // Only if you need to send cookies or HTTP Auth headers
});

export default {
  
  components: {
   
    QuestionCard // Register the QuestionCard component for use within this  component
  },
  data() {
    return {
      currentQuestion: 0, // Track the index of the current question being displayed      
      quizcompleted: false, // Track whether the quiz has been completed
      endQuiz: false, // Track whether the quiz has ended
      progress: 0, // Track the progress of the quiz
      questions: [
        {
          text: "Erbringt ihr Unternehmen Dienstleistungen in Deutschland?:", // Question text
          options: [ // Options for the checkbox question
            "Ja",
            "Nein"
          ],
          type: "radio", // Type of the question (checkbox)
          multiple: false // Whether multiple selections are allowed (true for checkboxes)
        },
        {
          text: "Bitte wählen Sie den Sektor aus in dem Ihr Unternehmen Dienstleistungen in Deutschland erbringt:", // Question text
          options: [ // Options for the dropdown menu
            "Energie",
            "Informationstechnik und Telekommunikation",
            "Transport und Verkehr",
            "Gesundheit",
            "Wasser",
            "Ernährung",
            "Finanz- und Versicherungswesen",
            "Siedlungsabfallentsorgung"
            
          ],
          type: "checkbox", // Type of the question (dropdown)
          multiple: true // Whether multiple selections are allowed (false for dropdown)
        },     
      ],
      selectedSectors: "", // Store the selected sectors
      selectedGeographies: [], // Store the selected geographies
      availableServices: [], // Store the available services based on the selected sector
      selectedServices: [], // Store the selected services
      availableSubServices: [], // Store the available sub-services based on the selected services
      selectedSubServices: [], // Store the selected sub-services
      availablePlantCategories: [], // Store the available plant categories based on the selected services and sub-services
      selectedPlantCategories: [], // Store the selected plant categories
      availableMeasuringCriteria: [], // Store the available measuring criteria based on the selected services, sub-services, and plant categories
      selectedMeasuringCriteria: [], // Store the selected measuring criteria
      availableThresholds: [], // Store the available thresholds based on the selected services
      selectedThresholds: [], // Store the selected thresholds
      dbAvailableServices: [], // Store the services received from AWS Lambda
      dbAvailableSubServices: [], // Store the sub-services received from AWS Lambda
      selectionArray: [], // Store the chained selected options as an array
      contactData: [], // Store the contact data
      allData: [], // Store all data loaded from the Excel file
      randomString : "", // Store a random string
      completeSectorsDataSet: [], // Store the complete data set for the selected sectors

    };
  },
  methods: {
    transformObjectToArray(obj, path = [], depth = 0) {
      if (Array.isArray(obj)) {
        return obj.map(item => this.transformObjectToArray(item, [], depth + 1));
      } else if (typeof obj === 'object' && obj !== null) {
        let result = [];
        for (let k in obj) {
          let newPath = path.slice();
          newPath.push(k);
          let subPaths = this.transformObjectToArray(obj[k], newPath, depth + 1);
          if (Array.isArray(subPaths[0])) {
            result.push([k, subPaths]);
          } else {
            result.push([k].concat(subPaths));
          }
        }
        return result;
      } else {
        if (depth === 5) {
          return [path.concat(obj).join(' ')];
        } else {
          return path.concat(obj);
        }
      }
    },
    // Define methods to handle user input and load questions dynamically
    // Load questions based on the selected sector and data from the corresponding Excel file
    async loadServices() {
      this.availableServices = await this.query_all_services_by_sector(this.selectedSectors);
      let transformedServices = Object.entries(this.availableServices).map(([sector, data]) => {
        return [sector, data.services.flat()];
      });
      this.selectionArray = transformedServices;
      console.log('transformedServices in loadServices:', transformedServices);    
      console.log('queryResult in loadServices:', this.availableServices);
      if (this.availableServices) {
        const serviceQuestion = {
          text: "Bitte wählen Sie die in Deutschland erbrachten Dienstleistungen für die ausgewählten Sektoren aus:", // Question text for selecting services
          type: "checkbox",
          options: this.selectionArray,
          multiple: true,            
        };
        console.log('Service Question in loadServices iteration:', serviceQuestion);
        this.questions.push(serviceQuestion); 
      }
    },
    // Load sub-services based on selected services
    async loadSubServices() {
      console.log('selectedServices before filtering in loadSubServices:', this.selectedServices); // Debugging      
      console.log('subServices in loadSubServices:', this.availableSubServices); // Debugging
      
      let sectorServiceMap = {}; // Create an object to map sectors to services
      this.selectedServices.forEach(answer => { // Iterate over the selected services
        let sector = answer.item[0];
        let service = answer.service;
        if (!sectorServiceMap[sector]) { // Check if the sector is already in the map
          sectorServiceMap[sector] = [];
        }
        sectorServiceMap[sector].push(service); // Add the service to the sector in the map
      });
      let transformedArray = Object.entries(sectorServiceMap); // Transform the object into an array
      this.selectionArray = transformedArray; // Store the transformed array in selectionArray

      console.log('this.selectionArray before in loadSubServices:', this.selectionArray);
      const subservices_by_service = await this.query_all_subservices(); // Load sub-services based on selected services
      console.log('subservices_by_service in loadSubServices:', subservices_by_service);
      let array3D = [];
      // Iterate over the selectionArray and add the sub-services to the services
      for (let i = 0; i < this.selectionArray.length; i++) { // Iterate over the selectionArray
        let sectorArray = [];
        
        sectorArray.push(this.selectionArray[i][0]);
        
        for (let j = 0; j < this.selectionArray[i][1].length; j++) {
          let serviceArray = [];
          
          serviceArray.push(this.selectionArray[i][1][j]);
          serviceArray.push(subservices_by_service[this.selectionArray[i][0]][this.selectionArray[i][1][j]].flat()); // Add the sub-services to the serviceArray
          
          sectorArray.push(serviceArray);
        }
        
        array3D.push(sectorArray);
      }
      console.log('array3D in loadSubServices:', array3D);
      this.selectionArray = array3D;
      console.log('this.selectionArray after in loadSubServices:', this.selectionArray);
      
      const subServiceQuestion = {
        text: "Bitte wählen Sie die in Deutschland erbrachten Unter-Dienstleistungen für die ausgewählten Dienstleistungen aus:", // Question text for selecting sub-services
        options: this.selectionArray, // Use the unique list of sub-services as options
        type: "checkbox", // Type of the question (checkbox)
        multiple: true // Allow multiple selections
      };
      this.questions.push(subServiceQuestion); // Add the new question to the questions array
    },
    // Load measuring criteria filtered on selected services and sub-services
    async loadPlantCategory() {
      console.log('this.selectionArray in loadPlantCategory:', this.selectionArray); // Debugging
      console.log('selectedSubServices in loadPlantCategory:', this.selectedSubServices); // Debugging
      
      let data = this.selectedSubServices;
      let result = [];
      for (let item of data) {
        let sector = item.sectorData[0];
        let service = item.serviceData[0];
        let selectedSubService = item.subservice;
  
        let sectorIndex = result.findIndex(s => s[0][0] === sector);
  
        if (sectorIndex === -1) {
          result.push([[sector], [[service, [selectedSubService]]]]);
        } else {
          let serviceIndex = result[sectorIndex][1].findIndex(s => s[0] === service);
  
          if (serviceIndex === -1) {
            result[sectorIndex][1].push([service, [selectedSubService]]);
          } else {
            if (!result[sectorIndex][1][serviceIndex][1].includes(selectedSubService)) {
              result[sectorIndex][1][serviceIndex][1].push(selectedSubService);
            }
          }
        }
      }
      let array = result;
      console.log('array in loadPlantCategory:', array); // Debugging
      this.selectionArray = array;
      console.log('this.selectionArray after array in loadPlantCategory:', this.selectionArray); // Debugging
      
      const queryPlantCategories = await this.query_all_plantcategories(); // Load plant categories based on selected services and sub-services
      console.log('queryPlantCategories in loadPlantCategory:', queryPlantCategories); // Debugging
      //this.selectionArray = queryPlantCategories;
      console.log('this.selectionArray after in loadPlantCategory:', this.selectionArray); // Debugging
      
      const plantCategoryQuestion = {
        text: "Bitte wählen Sie die Anlagenkategorien für die ausgewählten Sub-Dienstleistungen aus:", // Question text for selecting plant categories
        options: this.selectionArray, // Use the unique list of plant categories as options
        type: "checkbox", // Type of the question (checkbox)
        multiple: true // Allow multiple selections
      };
      this.questions.push(plantCategoryQuestion); // Add the new question to the questions array
    },
    
    async loadMeasuringCriteria() {
  console.log('selectedServices in loadMeasuringCriteria:', this.selectedServices); // Debugging
  console.log('selectedSubServices in loadMeasuringCriteria:', this.selectedSubServices); // Debugging 
  console.log('selectedPlantCategories in loadMeasuringCriteria:', this.selectedPlantCategories); // Debugging
  console.log('this.selectionArray before array in loadMeasuringCriteria:', this.selectionArray); // Debugging
  
  this.selectionArray.forEach(sector => {
    sector[1].forEach(service => {
      service[1].forEach(subservice => {
        subservice[1] = [];
      });
    });
  });
  
  this.selectedPlantCategories.forEach(selectedPlantCategoriesArray => {
    let sector = this.selectionArray.find(s => s[0] === selectedPlantCategoriesArray.sectorData[0]);

    if (sector) {
      let service = sector[1].find(s => s[0] === selectedPlantCategoriesArray.serviceData[0]);

      if (service) {
        let subservice = service[1].find(s => s[0] === selectedPlantCategoriesArray.subserviceData[0]);

        if (subservice) {
          subservice[1] = [...subservice[1], ...selectedPlantCategoriesArray.plantcategory];
        } else {
          service[1].push([selectedPlantCategoriesArray.subserviceData[0], selectedPlantCategoriesArray.plantcategory]);
        }
      } else {
        sector[1].push([selectedPlantCategoriesArray.serviceData[0], [[selectedPlantCategoriesArray.subserviceData[0], selectedPlantCategoriesArray.plantcategory]]]);
      }
    } else {
      this.selectionArray.push([selectedPlantCategoriesArray.sectorData[0], [[selectedPlantCategoriesArray.serviceData[0], [[selectedPlantCategoriesArray.subserviceData[0], selectedPlantCategoriesArray.plantcategory]]]]]);
    }
  });
  console.log('this.selectionArray after array in loadMeasuringCriteria:', this.selectionArray); // Debugging
  
  const queryMeasuringCriteria = await this.query_all_measuringcriteria(); // Load measuring criteria based on selected services, sub-services, and plant categories
  this.selectionArray = this.transformObjectToArray(queryMeasuringCriteria);
  console.log('queryMeasuringCriteria in loadMeasuringCriteria:', queryMeasuringCriteria); // Debugging
  console.log('this.selectionArray after in loadMeasuringCriteria:', this.selectionArray); // Debugging
 
  const measuringCriteriaQuestion = {
    text: "Bitte wählen Sie die Messkriterien für die ausgewählten Unter-Dienstleistungen aus:",
    options: this.selectionArray,
    type: "checkbox",
    multiple: true,      
  };
  this.questions.push(measuringCriteriaQuestion); // Add the new question to the questions array     
},
    async loadFinalQuestions() {
      const finalQuestions = [
        {
          text: "Abschlussinformationen",
          type: "final",
          options: [],
          multiple: false
        }
      ];      
      this.questions.push(...finalQuestions);
      
    },
    
    async query_services_by_sector(sector) {
  try {
    // Simulate finding services by sector in the dataset
    console.log('sector in query_services_by_sector:', sector);
    console.log('completeSectorsDataSet in query_services_by_sector:', this.completeSectorsDataSet);
    
   // Access the actual array within the Axios response object
   const dataSet = this.completeSectorsDataSet.data.complete_sectors_data_set;

// Filter services by sector (contains match)
const filteredServices = dataSet.flat().filter(item => item[1] && item[1].includes(sector));

// Extract only the third column and ensure unique values
const services = [...new Set(filteredServices.map(item => item[3]))];

console.log('services in query_services_by_sector:', services);
    if (!services.length) {
      return { services: [] };
    }
    return { services: services };
  } catch (error) {
    console.log(error);
    return { services: [] };
  }
},
    async query_completeSectorsDataSet(sectors) {
      console.log('sectors in query_completeSectorsDataSet:', sectors);
      try {
        const query_completeSectorsDataSet = await axios.post(`${apiBaseUrl}/kritis-check`, { sectors: sectors });
        console.log('query_completeSectorDataSet:', query_completeSectorsDataSet);
        return query_completeSectorsDataSet;
      } catch (error) {
        console.log(error);
      }
    },
    async query_all_services_by_sector(sectors) {
      console.log('sectors in query_all_services_by_sector:', sectors);
      const services_by_sector = {};
      
      for (const sector of sectors) {
        const query_services = await this.query_services_by_sector(sector);
        services_by_sector[sector] = query_services; // associate each sector with the services
      }
      return services_by_sector;
    },
    
    async query_subservices(sector, service) {
  try {
    // Log input parameters
    console.log('sector in query_subservices:', sector);
    console.log('service in query_subservices:', service);
    console.log('completeSectorsDataSet in query_subservices:', this.completeSectorsDataSet);

    // Access the actual array within the Axios response object
    const dataSet = this.completeSectorsDataSet.data.complete_sectors_data_set;
    console.log('dataSet in query_subservices:', dataSet);
    // Filter subservices by sector and service (contains match)
    const filteredSubservices = dataSet.flat().filter(item => 
      item[1] && item[1].includes(sector) && item[3] && item[3] === service);
    console.log('filteredSubservices in query_subservices:', filteredSubservices);

    // Extract the relevant column (e.g., fourth column) and ensure unique values
    const subservices = [...new Set(filteredSubservices.map(item => item[4]))];

    console.log('subservices in query_subservices:', subservices);

    
      // Return the found subservices from the local dataset
      return { subservices: subservices };
   
  } catch (error) {
    console.log(error);
    return { subservices: [] };
  }
},

    async query_all_subservices() {
  const subservices_by_service = {};
  console.log('this.selectionArray in query_all_subservices:', this.selectionArray);

  for (const sector of this.selectionArray) {
    const services = sector[1];
    for (const service of services) {
      try {
        const query_subservices = await this.query_subservices(sector[0], service);
        console.log('query_subservices in query_all_subservices:', query_subservices);

        // Ensure subservices_by_service[sector[0]] is defined
        if (!subservices_by_service[sector[0]]) {
          subservices_by_service[sector[0]] = {};
        }

        // Check if query_subservices and sub_services are defined
        if (query_subservices && query_subservices.subservices) {
          subservices_by_service[sector[0]][service] = query_subservices.subservices;
        } else {
          console.warn(`Subservices for sector ${sector[0]} and service ${service} are undefined`);
          subservices_by_service[sector[0]][service] = []; // Default to an empty array
        }
        console.log('subservices_by_service in query_all_subservices:', subservices_by_service);
      } catch (error) {
        console.error(`Error querying subservices for sector ${sector[0]} and service ${service}:`, error);
        // Handle the error appropriately, e.g., continue to the next service
      }
    }
  }
  return subservices_by_service;
},
async query_plantcategories(sector, service, sub_service) {
  try {
    // Log input parameters
    console.log('sector in query_plantcategories:', sector);
    console.log('service in query_plantcategories:', service);
    console.log('sub_service in query_plantcategories:', sub_service);
    console.log('completeSectorsDataSet in query_plantcategories:', this.completeSectorsDataSet);

    // Ensure completeSectorsDataSet is defined and has the expected structure
    if (!this.completeSectorsDataSet || !this.completeSectorsDataSet.data || !this.completeSectorsDataSet.data.complete_sectors_data_set) {
      throw new Error('completeSectorsDataSet is not defined or has an unexpected structure');
    }

    // Access the actual array within the Axios response object
    const dataSet = this.completeSectorsDataSet.data.complete_sectors_data_set;

    // Filter plant categories by sector, service, and sub_service (contains match)
    const filteredCategories = dataSet.flat().filter(item => 
      item[1] && item[1].includes(sector) &&
      item[3] && item[3].includes(service) &&
      item[4] && item[4].includes(sub_service)
    );

    // Extract the relevant column (e.g., fifth column) and ensure unique values
    const plantCategories = [...new Set(filteredCategories.map(item => item[5]))];

    console.log('plantCategories in query_plantcategories:', plantCategories);

    if (plantCategories.length) {
      // Return the found plant categories from the local dataset
      return { plantCategories: plantCategories };
    }

    // If no plant categories found locally, make the axios request
    const response = await axios.post(`${apiBaseUrl}/kritis-check`, { sector: sector, service: service, sub_service: sub_service });
    console.log('query_plantcategories from API:', response);

    // Ensure the response has the expected structure before accessing properties
    if (response.data && response.data.plant_categories) {
      return { plantCategories: response.data.plant_categories };
    } else {
      throw new Error('API response does not have the expected structure');
    }
  } catch (error) {
    console.error('Error in query_plantcategories:', error);
    return { plantCategories: [] };
  }
},
async query_all_plantcategories() {
      const map = new Map();
      for (const sector of this.selectionArray) {
        const sectorName = Array.from(sector[0])[0];
        const services = sector[1];
        if (!map.has(sectorName)) {
          map.set(sectorName, new Map());
        }
        const sectorMap = map.get(sectorName);
        for (const service of services) {
          const serviceName = service[0];
          const subservices = service[1];
          if (!sectorMap.has(serviceName)) {
            sectorMap.set(serviceName, new Map());
          }
          const serviceMap = sectorMap.get(serviceName);
          for (const subservice of subservices) {
            const query_plantcategories = await this.query_plantcategories(sectorName, serviceName, subservice);
            console.log('query_plantcategories in query_all_plantcategories:', query_plantcategories);
            if (!serviceMap.has(subservice)) {
              serviceMap.set(subservice, []);
              console.log('serviceMap in query_all_plantcategories:', serviceMap);
          }
  const plantcategories = serviceMap.get(subservice);
  console.log('plantcategories in query_all_plantcategories:', plantcategories);
  
  // Check if query_plantcategories is directly an array
  if (Array.isArray(query_plantcategories)) {
    plantcategories.push(...query_plantcategories);
  } else if (query_plantcategories && typeof query_plantcategories === 'object' && Array.isArray(query_plantcategories.plantCategories)) {
    // If query_plantcategories is an object with an array under the plantCategories key
    plantcategories.push(...query_plantcategories.plantCategories);
  } else {
    console.error('query_plantcategories is not in the expected format:', query_plantcategories);
    // Optionally handle the case when query_plantcategories is neither an array nor the expected object format
  }
}
        }
      }
      console.log('map in query_all_plantcategories:', map);
      const plantcategories_by_subservice = this.mapToMultidimensionalArray(map);
      console.log('plantcategories_by_subservice in query_all_plantcategories:', plantcategories_by_subservice);
      this.selectionArray = plantcategories_by_subservice;
      console.log('this.selectionArray after in query_all_plantcategories:', this.selectionArray);
      return plantcategories_by_subservice;
    },
    //transform any map into a perfect array
    mapToMultidimensionalArray(map) {
      console.log('map in mapToMultidimensionalArray:', map);
  const result = [];

  map.forEach((value, key) => {
    console.log(`Processing key: ${key}, value:`, value);

    // Recursively transform if the value is another Map
    if (value instanceof Map) {
      value = this.mapToMultidimensionalArray(value);
    } else if (Array.isArray(value)) {
      console.log(`Array value detected:`, value);
      // Ensure we do not split strings
      value = value.map(item => {
        if (typeof item === 'string') {
          console.log('String item detected:', item);
          return [item]; // Keep strings intact
        } else if (item instanceof Map) {
          console.log('Map item detected:', item);
          return this.mapToMultidimensionalArray(item); // Recursively transform maps
        }
        console.log('Non-string item detected:', item);
        return item;
      });
    }

    // Push the key-value pair as an array
    console.log(`Pushing [${key}, ${value}] to result`);
    result.push([key, value]);
  });

  console.log('Result in mapToMultidimensionalArray:', result);
  return result;
}
,
query_measuringcriteria(sector, service, sub_service, plantcategory) {
  try {
    // Ensure completeSectorsDataSet is defined and has the expected structure
    if (!this.completeSectorsDataSet || !this.completeSectorsDataSet.data || !this.completeSectorsDataSet.data.complete_sectors_data_set) {
      throw new Error('completeSectorsDataSet is not defined or has an unexpected structure');
    }

    // Access the actual array within the Axios response object
    const dataSet = this.completeSectorsDataSet.data.complete_sectors_data_set;

    // Filter measuring criteria by sector, service, sub_service, and plant category (contains match)
    const filteredCriteria = dataSet.flat().filter(item => 
      item[1] && item[1].includes(sector) &&
      item[3] && item[3].includes(service) &&
      item[4] && item[4].includes(sub_service) &&
      item[5] && item[5].includes(plantcategory)
    );

    // Extract the relevant column (e.g., sixth column) and ensure unique values
    const measuringCriteria = [...new Set(filteredCriteria.map(item => item[6]))];

    console.log('measuringCriteria in query_measuringcriteria:', measuringCriteria);

    return measuringCriteria;
  } catch (error) {
    console.error('Error in query_measuringcriteria:', error);
    return [];
  }
},
async query_all_measuringcriteria() {
  const measuringcriteria_by_plantcategory = {};
  console.log('this.selectionArray in query_all_measuringcriteria:', this.selectionArray);

  for (const sector of this.selectionArray) {
    const sectorName = sector[0];
    const services = sector[1];
    if (!measuringcriteria_by_plantcategory[sectorName]) {
      measuringcriteria_by_plantcategory[sectorName] = {};
    }
    const sectorMap = measuringcriteria_by_plantcategory[sectorName];
    for (const service of services) {
      const serviceName = service[0];
      const subservices = service[1];
      if (!sectorMap[serviceName]) {
        sectorMap[serviceName] = {};
      }
      const serviceMap = sectorMap[serviceName];
      for (const subservice of subservices) {
        const subserviceName = subservice[0];
        const plantcategories = subservice[1];
        if (!serviceMap[subserviceName]) {
          serviceMap[subserviceName] = {};
        }
        const subserviceMap = serviceMap[subserviceName];
        for (const plantcategory of plantcategories) {
          console.log('sectorName, serviceName, subserviceName, plantcategory:', sectorName, serviceName, subserviceName, plantcategory);
          const measuringcriteria = this.query_measuringcriteria(sectorName, serviceName, subserviceName, plantcategory);
          if (measuringcriteria && Array.isArray(measuringcriteria)) {
            if (!subserviceMap[plantcategory]) {
              subserviceMap[plantcategory] = [];
            }
            subserviceMap[plantcategory].push(...measuringcriteria);
          } else {
            console.error('Error querying measuring criteria:', measuringcriteria);
          }
        }
      }
    }
  }
  console.log('measuringcriteria_by_plantcategory:', measuringcriteria_by_plantcategory);
  return measuringcriteria_by_plantcategory;
},
    async query_thresholds(Sector, Service, SubService, PlantCategory, MeasuringCriteria) {
      try {
        const query_thresholds = await axios.post(`${apiBaseUrl}/kritis-check`, { Sector: Sector, Service: Service, SubService: SubService, PlantCategory: PlantCategory, MeasuringCriteria: MeasuringCriteria });
        console.log('query_thresholds:', query_thresholds);
        return query_thresholds;
      } catch (error) {
        console.log(error);
      }
    },
    async query_all_thresholds(measuringcriteria_by_plantcategory) {
      const thresholds_by_measuringcriteria = {};
      for (const sector in measuringcriteria_by_plantcategory) {
        const services = measuringcriteria_by_plantcategory[sector];
        for (const service in services) {
          const subservices = services[service];
          for (const subservice in subservices) {
            const plantcategories = subservices[subservice];
            for (const plantcategory in plantcategories) {
              const measuringcriteria = plantcategories[plantcategory];
              for (const criteria in measuringcriteria) {
                const query_thresholds = await this.query_all_thresholds(sector, service, subservice, plantcategory, criteria);
                if (!thresholds_by_measuringcriteria[sector]) {
                  thresholds_by_measuringcriteria[sector] = {};
                }
                if (!thresholds_by_measuringcriteria[sector][service]) {
                  thresholds_by_measuringcriteria[sector][service] = {};
                }
                if (!thresholds_by_measuringcriteria[sector][service][subservice]) {
                  thresholds_by_measuringcriteria[sector][service][subservice] = {};
                }
                if (!thresholds_by_measuringcriteria[sector][service][subservice][plantcategory]) {
                  thresholds_by_measuringcriteria[sector][service][subservice][plantcategory] = {};
                }
                thresholds_by_measuringcriteria[sector][service][subservice][plantcategory][criteria] = query_thresholds; // associate each measuringcriteria with the thresholds
              }
            }
          }
        }
      }
      return thresholds_by_measuringcriteria;
    },
    // combines the results of all questions and sends them to lead-matrix DB
    async sendToLeadMatrixDB() {
      const globalData = {
        //contactData: this.contactData,
        companyName: this.contactData.companyName,
        contactEmail: this.contactData.contactEmail,
        contactFirstName: this.contactData.contactFirstName,
        contactLastName: this.contactData.contactLastName,
        contactRole: this.contactData.contactRole,
        disclaimerConsent: this.contactData.disclaimerConsent,
        emailConsent: this.contactData.emailConsent,        
        selectedGeographies: this.selectedGeographies[0],
        /* selectedSectors: this.selectedSectors,
        selectedServices: this.selectedServices,
        selectedSubServices: this.selectedSubServices,
        selectedPlantCategories: this.selectedPlantCategories,
        selectedMeasuringCriteria: this.selectedMeasuringCriteria,
        selectedThresholds: this.selectedThresholds */
      };
      
      console.log('globalData in sendToLeadMatrixDB:', globalData);
      // iterate over this.selectedMeasuringCriteria and build for every array element on the first dimension a nested object containing sector, service, subservice, plantcategory, measurincriteria and criteriaValue
      let selectionSummary = [];
      console.log('selectedMeasuringCriteria in sendToLeadMatrixDB:', this.selectedMeasuringCriteria);
      console.log('selectedMeasuringCriteria length in sendToLeadMatrixDB:', this.selectedMeasuringCriteria.length);
      if (this.selectedMeasuringCriteria === "Nein") {
        console.log('No measuring criteria selected!');
        selectionSummary = [];
      }
      else{
      selectionSummary = this.selectedMeasuringCriteria.map(item => ({
      sector: item.sectorData[0],
      service: item.serviceData[0],
      subservice: item.subserviceData[0],
      plantcategory: item.plantcategoryData[0],
      measuringcriteria: item.measuringcriteria[0], // Assuming the first element of the first array is the desired value
      criteriaValue: item.criteriaValue
      
    }));
    console.log('selectionSummary in sendToLeadMatrixDB:', selectionSummary);

  }
    
    //send the selectionSummary via aws lamda function to the lead-matrix DB.
    try {
      let response;
      if (selectionSummary.length === 0) {
        console.log('No measuring criteria selected!');
        response = await axios.post(`${apiBaseUrl2}/lead-matrix`, { globalData });
      }
      else {
        console.log('Measuring criteria selected!');
         response = await axios.post(`${apiBaseUrl2}/lead-matrix`, { globalData, selectionSummary });
      }
      
      console.log('response in sendToLeadMatrixDB:', response);
      this.randomString = response.data.randomstring;
      console.log('Randomstring in sendToLeadMatrixDB:',this.randomString); // Logs the randomstring value to the console
      console.log('apiBaseUrl3 in sendToLeadMatrixDB:',apiBaseUrl3); 
      const url = `${apiBaseUrl3}/${this.randomString}`; // Construct the URL
      console.log('URL:', url); // Debugging
      try {
        const response = await axiosInstance.get(url, { validateStatus: false });
        if (response.status === 302) {
          const redirectUrl = response.headers.location;
          console.log('Redirecting to:', redirectUrl);
          window.location.href = redirectUrl;
        } else {
          console.log(response.data); // Handle the response
        }
      } catch (error) {
        console.error('Error fetching data:', error); // Handle the error
      }
    }
    catch (error) {
      console.error('Error sending data:', error); // Handle the error
    }
  },

   async handleAnswer(answer) {
      console.log('apiBaseUrl:', apiBaseUrl);
      console.log('apiBaseUrl2:', apiBaseUrl2);
      console.log('apiBaseUrl3:', apiBaseUrl3);
      console.log('answer in handleAnswer:', answer); // Debugging
      console.log('answer:', answer);
      
      if(answer['companyName'] !== undefined){
        console.log('send lead data!');      
        this.contactData = answer;
        console.log('contactData in handleAnswer:', this.contactData); // Debugging
        this.sendToLeadMatrixDB();
        this.endQuiz = true;        
      }

      if(answer['answer'] === undefined ){
        console.log('answer reset because undefined', answer['answer'])
        answer['answer'] = [];
      }
      console.log('answer before bein check:', answer['answer'])
      if(Array.isArray(answer['answer']) && answer['answer'][0] === 'Nein'){
  console.log('answer reset because Nein', answer['answer'])
  answer['answer'] = [];
}
      
     
      console.log('currentQuestion in handleAnswer before if:', this.currentQuestion); // Debugging
      if (this.currentQuestion === 0 && answer['answer'].length !== 0 && answer['answer'] === 'Ja') {
        this.selectedGeographies = answer['answer']; // Store the selected geographies
        
        console.log('selectedGeographies in handleAnswer:', this.selectedGeographies); // Debugging
      } else if (this.currentQuestion === 1 && answer['answer'].length !== 0) {
        this.selectedSectors = answer['answer']; // Store the selected sector
        console.log('selectedSectors in handleAnswer CQ1:', this.selectedSectors); // Debugging
        this.completeSectorsDataSet =  await this.query_completeSectorsDataSet(this.selectedSectors);
        console.log('completeSectorsDataSet in handleAnswer:', this.completeSectorsDataSet); // Debugging
        this.loadServices(); // Load questions based on the selected sector
        console.log('selectedSector in handleAnswer:', this.selectedSectors); // Debugging   
      } else if (this.currentQuestion === 2 && answer['answer'].length !== 0) {
        console.log('answer in handleAnswer array CQ2:', answer['answer']);       
        this.selectedServices = answer['answer']; 
        console.log('selectedServices in handleAnswer CQ2:', this.selectedServices); // Debugging    
        this.loadSubServices(); // Load sub-services based on selected services
      } else if (this.currentQuestion === 3 && answer['answer'].length !== 0) {
        console.log('answer in handleAnswer CQ3 array:', answer['answer']); //
        this.selectedSubServices = answer['answer']; // Store the selected sub-services
        console.log('selectedSubServices in handleAnswer CQ3 :', this.selectedSubServices); // Debugging
        this.loadPlantCategory(); // Load plant categories based on selected services and sub-services
      } else if (this.currentQuestion === 4 && answer['answer'].length !== 0) {
        console.log('answer in handleAnswer CQ4 array:', answer['answer']);      
        this.selectedPlantCategories = answer['answer']; // Store selected plant categories
        console.log('selectedPlantCategories in handleAnswer CQ4:', this.selectedPlantCategories); // Debugging
        this.loadMeasuringCriteria(); // Load measuring criteria based on selected services, sub-services, and plant categories
      } else if ( answer['answer'].length === 0 || this.currentQuestion === 5 || answer['answer']==="Nein") {
        console.log('Quiz completed!');
        this.selectedMeasuringCriteria = answer['answer']; // Store the selected measuring criteria
        console.log('selectedMeasuringCriteria in handleAnswer:', this.selectedMeasuringCriteria); // Debugging
        this.quizcompleted = true; // Set quizcompleted to true
        this.currentQuestion = this.questions.length - 1; // Skip to the final question
        this.loadFinalQuestions(); // Load final questions
      } 
      
      console.log('endQuiz in handleAnswer:', this.endQuiz); // Debugging
       // Update progress
      
      this.currentQuestion++; // Move to the next question
      if (this.endQuiz === true) {        
        this.progress = 100;
        //send trigger request to AWS lambda VUE_APP_API_BASE_URL3
        
        

      }else{
        this.progress = ((this.currentQuestion) / 7) * 100;
      }

      
      console.log('Continue', this.currentQuestion); // Debugging
      console.log('currentQuestion in handleAnswer:', this.currentQuestion); // Debugging
      console.log('questions in handleAnswer:', this.questions); // Debugging
     

      
    },
    getOptionsForCurrentQuestion() {
      console.log('options for current question in getOptionsForCurrentQuestion:', this.questions[this.currentQuestion].options); // Debugging
      return this.questions[this.currentQuestion].options; // Return options for the current question
    }
  }
};
</script>
<style scoped>
/* General container styles */
</style>




