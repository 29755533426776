<template>
  <!--Zeige Kontaktformular wenn message_sent==false --> 
  
    <div class="m-0 p-0">
      
  </div>
  <div v-if="!message_sent" class="contact-page flex flex-col items-center justify-center w-full ">
  <div class="contact-page flex flex-col items-center justify-center w-full ">
    <h1 class="text-3xl font-semibold text-blue-700 mb-6">Kontaktieren Sie uns!</h1>
    <form @submit.prevent="submitForm" class="w-full max-w-lg bg-gray-100 p-6 rounded shadow-md">
      <div class="mb-4">
        <label for="name" class="block text-gray-700 text-2xl font-bold mb-2">Name:</label>
        <input type="text" id="name" v-model="name" placeholder="Optional!" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
      </div>
      <div class="mb-4">
        <label for="email" class="block text-gray-700 text-2xl font-bold mb-2">E-Mail:</label>
        <input type="email" id="email" v-model="email" placeholder="Pflichtfeld!" required class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
      </div>
      <div class="mb-4">
        <label for="message" class="block text-gray-700 text-2xl font-bold mb-2">Nachricht:</label>
        <textarea id="message" v-model="message"  placeholder="Pflichtfeld!" required class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" rows="5"></textarea>
      </div>
      <!--checkbox to collect consent to reach out (in german with link to the Datenschuzrichtlinie)-->
      <div class="mb-4">
  <input type="checkbox" id="consent" required class="form-checkbox h-5 w-5 text-gray-600">
  <label for="consent" class="text-gray-700 text-lg ml-2">Ich bin damit einverstanden, dass meine Daten zur Kontaktaufnahme gespeichert werden. <a href="/privacy-policy" class="text-blue-500 hover:underline">Datenschutzrichtlinie</a></label>
</div>
      <div class="flex items-center justify-center">
        <button type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline text-xl">Absenden</button>
      </div>
    </form>
  </div>
</div>
<div v-if="message_sent" class="contact-page flex flex-col items-center justify-center w-full">
  <!--Danksagung für die Kontakaufnahme-->
  <div class="contact-page flex flex-col items-center justify-center w-full">
    <h1 class="text-3xl font-semibold text-blue-700 mb-6">Vielen Dank für Ihre Nachricht!</h1>
    <p class="text-lg text-gray-700">Wir haben Ihre Nachricht erhalten und werden uns so schnell wie möglich bei Ihnen melden.</p>
</div>
</div>
  
  </template>

<script>
const apiBaseUrl4 = process.env.VUE_APP_API_BASE_URL4;
import axios from 'axios';

export default {
   data() {
    return {
      name: '',
      email: '',
      message: '',
      message_sent: false
    };
  },
  methods: {
    async submitForm() {
      try {
        console.log('submit to: '+`${apiBaseUrl4}/contact-form`);
        
          const response = await axios.post(`${apiBaseUrl4}/contact-form`, {
            name: this.name,
            email: this.email,
            message: this.message
          });

          if (response.status !== 200) {
            throw new Error('Network response was not ok');
          }

          console.log('Nachricht erfolgreich gesendet!');
          this.message_sent = true;
        } catch (error) {
          alert('Es gab ein Problem beim Senden der Nachricht: ' + error.message);
        }
    }
  }
  }

</script>

<style scoped>
.contact-page {
  min-height: 100vh;
}
</style>