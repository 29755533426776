<template>
    <div class="container mx-auto p-4 md:p-6 sticky bottom-0 w-full bg-gray-200 border-t shadow-md z-50" v-if="!cookiebannerclicked">
      <vue-cookie-accept-decline
        :debug="false"
        :disableDecline="false"
        :showPostponeButton="false"
        @clicked-accept="cookieClickedAccept"
        @clicked-decline="cookieClickedDecline"
        @clicked-postpone="cookieClickedPostpone"
        @removed-cookie="cookieRemovedCookie"
        @status="cookieStatus"
        elementId="cookie1"
        position="bottom-right"
        ref="cookie1"
        transitionName="fade"
        type="floating"
        class="flex flex-col md:flex-row items-center justify-between space-y-2 md:space-y-0 md:space-x-4 bg-gray-200"
      >
        <template #message>
          <div class="text-gray-800 text-base md:text-xl">
            <span>Wir verwenden Cookies, um sicherzustellen, dass Sie das beste Erlebnis auf unserer Website haben.</span>
            <br>
            <a href="/cookie-policy" class="text-blue-500 hover:text-blue-700">Cookie-Richtlinie</a>
          </div>
        </template>
        <template #acceptContent>
          <button class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-150 ease-in-out text-base md:text-xl">
            Cookies Erlauben
          </button>
        </template>
        <template #declineContent>
          <button class="text-gray-500 hover:text-gray-700 bg-transparent py-2 px-4 rounded transition duration-150 ease-in-out text-base md:text-xl">
            Cookies Ablehnen
          </button>
        </template>
      </vue-cookie-accept-decline>
    </div>
  </template>
  
  <script>
  import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
  import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';
  
  export default {
    components: {
      VueCookieAcceptDecline,
    },
    data() {
      return {
        cookiebannerclicked: localStorage.getItem('cookiebannerclicked') === 'true',
      };
    },
    created() {
      console.log('cookiebannerclicked on created:', this.cookiebannerclicked);
      if (this.cookiebannerclicked && localStorage.getItem('cookieConsent') === 'accepted') {
        this.initializeGTM();
      }
    },
    methods: {
      cookieClickedAccept() {
        this.cookiebannerclicked = true;
        localStorage.setItem('cookiebannerclicked', true);
        localStorage.setItem('cookieConsent', 'accepted');
        console.log('cookiebannerclicked on accept:', this.cookiebannerclicked);
        this.initializeGTM(); // Initialize GTM when cookies are accepted
      },
      cookieClickedDecline() {
        this.cookiebannerclicked = true;
        localStorage.setItem('cookiebannerclicked', true);
        localStorage.setItem('cookieConsent', 'declined');
        console.log('cookiebannerclicked on decline:', this.cookiebannerclicked);
        // Handle decline button click
      },
      cookieClickedPostpone() {
        // Handle postpone button click
      },
      cookieRemovedCookie() {
        // Handle cookie removal
      },
      initializeGTM() {
  // Check if GTM script is already loaded
  if (!document.getElementById('gtm-script')) {
    // Load the GTM script dynamically
    (function(w,d,s,l,i){
      w[l]=w[l]||[];
      w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
      j.async=true;
      j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
      j.id='gtm-script'; // Add id for later checking
      f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-WDTSTQ54');
  }
},
    },
  };
  </script>
  
  <style scoped>
  /* Add your specific styles for the cookie consent banner here */
  </style>
  