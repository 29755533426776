import { createApp } from 'vue';
import { createMetaManager } from 'vue-meta'; // Ensure you're importing from 'vue-meta/next'
import App from './App.vue';
import router from './router';
import './global.css';
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';





const app = createApp(App);
app.component('vue-cookie-accept-decline', VueCookieAcceptDecline);

app.use(router);
const metaManager = createMetaManager();
app.use(metaManager);
app.mount('#app');


