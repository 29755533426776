<!-- src/components/Footer.vue -->
<template>
    <footer class="bg-gray-100 py-8 mx-auto">
      <div class="container mx-auto flex flex-col md:flex-row items-center justify-between">
        
          <a href="/startseite" class="text-blue-500 hover:text-blue-700 mx-2 text-xl">Startseite</a>
          <a href="/impressum" class="text-blue-500 hover:text-blue-700 mx-2 text-xl">Impressum</a>
          <a href="/kontakt" class="text-blue-500 hover:text-blue-700 mx-2 text-xl">Kontakt</a>
          <a href="/privacy-policy" class="text-blue-500 hover:text-blue-700 mx-2 text-xl">Datenschutzrichtlinie</a>
          <a href="/disclaimer" class="text-blue-500 hover:text-blue-700 mx-2 text-xl">Haftungsbeschränkung</a>
          <a href="/cookie-policy" class="text-blue-500 hover:text-blue-700 mx-2 text-xl">Cookie-Richtlinie</a>
          <a href="/newsletter" class="text-blue-500 hover:text-blue-700 mx-2 text-xl">Newsletter</a>
        
       
      </div>
      <p class="text-gray-500 m-4">&copy; 2024 Kritis Check. Alle Rechte vorbehalten.</p>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterComponent'
  }
  </script>
  
  <style scoped>
  /* Fügen Sie hier Ihre spezifischen Stile für die Footer-Komponente hinzu */
  </style>
  