<template>
  <div class="max-w-4xl mx-auto my-8 p-6 bg-white rounded-lg shadow-lg text-gray-800">
    <h1 class="text-2xl font-bold mb-6 text-center text-blue-600">Cookie-Richtlinie</h1>
    
    <p class="mb-6 text-justify">
      <strong>1. Was sind Cookies?</strong><br />
      Cookies sind kleine Textdateien, die auf Ihrem Endgerät gespeichert werden, wenn Sie unsere Website besuchen. Diese Textdateien können Informationen über Ihre Nutzung unserer Website speichern.
    </p>
    
    <p class="mb-6 text-left">
      <strong>2. Verwendung von Cookies</strong><br />
      Wir verwenden Cookies, um Ihre Benutzererfahrung auf unserer Website zu verbessern, die Leistung unserer Website zu analysieren und zu verstehen, wie Sie unsere Website nutzen. Folgende Arten von Cookies kommen zum Einsatz:
      <ul class="list-disc list-inside text-left">
        <li class="mb-3"><strong>Notwendige Cookies:</strong> Diese Cookies sind erforderlich, damit Sie sich auf der Website bewegen und ihre Funktionen nutzen können.</li>
        <li class="mb-3"><strong>Leistungs-Cookies:</strong> Diese Cookies sammeln Informationen darüber, wie Besucher eine Website nutzen, z.B. welche Seiten am häufigsten besucht werden.</li>
        <li class="mb-3"><strong>Funktionale Cookies:</strong> Diese Cookies ermöglichen es der Website, sich an die von Ihnen getroffenen Entscheidungen zu erinnern (wie z.B. Ihren Benutzernamen, Ihre Sprache oder die Region, in der Sie sich befinden).</li>
        <li class="mb-3"><strong>Targeting- und Werbe-Cookies:</strong> Diese Cookies werden verwendet, um Anzeigen zu liefern, die für Sie und Ihre Interessen relevanter sind.</li>
        <li class="mb-3"><strong>Analyse-Cookies:</strong> Diese Cookies werden verwendet, um das Nutzerverhalten auf unserer Website zu analysieren und zu verstehen. Dazu gehört auch die Verwendung von Microsoft Clarity, das uns hilft, Mausbewegungen, Scrollverhalten und Klicks auf unserer Website aufzuzeichnen.</li>
      </ul>
    </p>
    
    <p class="mt-6 mb-6 text-justify">
      <strong>3. Verwaltung und Löschung von Cookies</strong><br />
      Sie können Cookies über die Einstellungen Ihres Browsers verwalten und löschen. Die meisten Browser ermöglichen es Ihnen, alle Cookies zu akzeptieren oder abzulehnen oder nur bestimmte Arten von Cookies zu akzeptieren. Informationen hierzu finden Sie in der Hilfe-Funktion Ihres Browsers. Bitte beachten Sie, dass das Blockieren oder Löschen von Cookies die Funktionalität unserer Website beeinträchtigen kann.
    </p>
    
    <p class="mb-6 text-justify">
      <strong>4. Einwilligung und Änderungen der Cookie-Richtlinie</strong><br />
      Durch die Nutzung unserer Website erklären Sie sich mit der Verwendung von Cookies einverstanden. Diese Cookie-Richtlinie kann von Zeit zu Zeit geändert werden. Alle Änderungen werden auf dieser Seite veröffentlicht. Diese Cookie-Richtlinie wurde zuletzt am 17.07.2024 aktualisiert.
    </p>
    
    <p class="mb-6 text-justify">
      <strong>5. Einwilligung mit Cookie-Banner</strong><br />
      Unsere Website nutzt ein Cookie-Banner zur Einholung der Einwilligung zur Speicherung bestimmter Cookies in Ihrem Browser oder zum Einsatz bestimmter Technologien.
    </p>
    
    <p class="mb-6 text-justify">
      <strong>6. Nutzung von Google Analytics und Google Tag Manager</strong><br />
      
      <strong>Google Analytics</strong><br />
      Unsere Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland.
      
      Google Analytics verwendet sogenannte „Cookies“. Das sind Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.
      
      Wir haben die IP-Anonymisierung auf dieser Website aktiviert. Das bedeutet, dass Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor der Übermittlung in die USA gekürzt wird. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
      
      Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software oder das Ablehnen durch das Cookie-Banner verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Weitere Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerklärung von Google: <a href="https://support.google.com/analytics/answer/6004245?hl=de" class="text-blue-600">https://support.google.com/analytics/answer/6004245?hl=de</a>.
      
      <strong>Google Tag Manager</strong><br />
      Wir setzen den Google Tag Manager ein. Anbieter ist die Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland.
      
      Der Google Tag Manager ist ein Tool, mit dessen Hilfe wir Tracking- oder Statistik-Tools und andere Technologien auf unserer Website einbinden können. Der Google Tag Manager selbst erstellt keine Nutzerprofile, speichert keine Cookies und nimmt keine eigenständigen Analysen vor. Er dient lediglich der Verwaltung und Ausspielung der über ihn eingebundenen Tools. Der Google Tag Manager erfasst jedoch Ihre IP-Adresse, die auch an das Mutterunternehmen von Google in die Vereinigten Staaten übertragen werden kann.
      
      Die Nutzung des Google Tag Managers erfolgt auf Grundlage Ihrer Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar.
      
      Weitere Informationen zum Google Tag Manager finden Sie hier: <a href="https://www.google.com/intl/de/tagmanager/use-policy.html" class="text-blue-600">https://www.google.com/intl/de/tagmanager/use-policy.html</a>.
    </p>
    <p class="mb-6 text-justify">
  <strong>Google Ads und Conversion-Tracking</strong><br />
  Unsere Website verwendet Google Ads und im Rahmen von Google Ads das Conversion-Tracking. Wenn Sie auf eine von Google geschaltete Anzeige klicken, wird ein Cookie für das Conversion-Tracking auf Ihrem Endgerät abgelegt. Diese Cookies verlieren nach 30 Tagen ihre Gültigkeit und dienen nicht der persönlichen Identifizierung der Nutzer. Besuchen Sie bestimmte Seiten dieser Website und das Cookie ist noch nicht abgelaufen, können Google und wir erkennen, dass der Nutzer auf die Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde.<br />
  <br />
  Jeder Google Ads-Kunde erhält ein anderes Cookie. Cookies können somit nicht über die Websites von Ads-Kunden nachverfolgt werden. Die mithilfe des Conversion-Cookies eingeholten Informationen dienen dazu, Conversion-Statistiken für Ads-Kunden zu erstellen, die sich für Conversion-Tracking entschieden haben. Die Kunden erfahren die Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit denen sich Nutzer persönlich identifizieren lassen.<br />
  <br />
  Die Speicherung von „Conversion-Cookies“ und die Nutzung dieses Tracking-Tools erfolgen auf Grundlage Ihrer Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar.<br />
  <br />
  Weitere Informationen zu Google Ads und Google Conversion-Tracking finden Sie in den Datenschutzbestimmungen von Google: <a href="https://policies.google.com/privacy" class="text-blue-600">https://policies.google.com/privacy</a>.
</p>

      <p class="mb-6 text-justify">
      <strong>Microsoft Clarity</strong><br />
      Unsere Website nutzt Funktionen des Webanalysedienstes Microsoft Clarity. Anbieter ist die Microsoft Ireland Operations Limited, One Microsoft Place, South County Business Park, Leopardstown, Dublin 18, Irland.
      
      Microsoft Clarity verwendet Technologien wie Cookies oder Device-Fingerprinting, um das Nutzerverhalten auf unserer Website zu analysieren. Dabei werden insbesondere Mausbewegungen und Scrollverhalten aufgezeichnet und grafisch dargestellt (Heatmaps). Sitzungen können aufgezeichnet werden, sodass wir die Seitennutzung in Form von Videos ansehen können.
      
      Die durch Clarity gesammelten Daten werden auf Servern von Microsoft in den USA gespeichert. Wir weisen darauf hin, dass in den USA kein mit der EU vergleichbares Datenschutzniveau garantiert werden kann. Die Nutzung von Clarity erfolgt auf Grundlage Ihrer Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar.
      
      Weitere Details zum Datenschutz von Clarity finden Sie hier: <a href="https://docs.microsoft.com/en-us/clarity/faq" class="text-blue-600">https://docs.microsoft.com/en-us/clarity/faq</a>.
    </p>

    
    <p class="mb-6 text-justify">
      <strong>7. Kontaktinformationen</strong><br />
      Wenn Sie Fragen zu unserer Verwendung von Cookies haben, können Sie uns unter folgender Adresse kontaktieren:<br />
      Marcel Bornhöfft<br />
      Velper Str. 2, 31303 Burgdorf<br />
      webmaster@kritischeck.de
    </p>
  </div>
</template>

<script>
export default {
  name: 'CookiePolicy'
}
</script>

<style scoped>
/* Custom class for justified text */
.text-justify {
  text-align: justify;
}
</style>
