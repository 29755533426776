<template>
    <div class="m-0 p-0">
      
    </div>
    <main class="container mx-auto p-6 bg-white shadow-md rounded-lg my-8">
      <h1 class="text-3xl font-bold mb-4">Impressum</h1>
      <div class="mb-6 text-justify">
        <h2 class="text-2xl font-semibold mb-2 text-justify">Angaben gemäß § 5 TMG</h2>
        <p>Marcel Bornhöfft<br>
        Velper Str. 2<br>
        31303 Burgdorf<br>
        </p>
      </div>
      <div class="mb-6 text-justify">
        <h2 class="text-2xl font-semibold mb-2 text-justify">Kontakt</h2>
        <p>Telefon: +4917647764128<br>        
        E-Mail: <a href="mailto:marcelb@einfachitsicher.de" class="text-blue-500 hover:underline">webmaster@kritischeck.de</a></p>
      </div>
      <div class="mb-6 text-justify">
        <h2 class="text-2xl font-semibold mb-2 text-justify">Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV</h2>
        <p>Marcel Bornhöfft<br>
            Velper Str. 2<br>
            31303 Burgdorf</p>
      </div>
      <div class="mb-6 text-justify">
        <h2 class="text-2xl font-semibold mb-2 text-justify">Streitschlichtung</h2>
        <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr" class="text-blue-500 hover:underline">https://ec.europa.eu/consumers/odr</a>. Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
      </div>
      <div>
        <h2 class="text-2xl font-semibold mb-2 text-justify">Haftung für Inhalte</h2>
        <p class="mb-6 text-justify">Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
      </div>
    </main>
    
  </template>