<template>
  <div class="question-card-container p-6 bg-gray-100 rounded shadow-md my-6 flex justify-center">
    <!-- Display the question text -->
    <div class="question-container p-4 rounded flex flex-col items-center mx-auto w-full max-w-full">
      <h2 class="text-lg md:text-xl lg:text-4xl font-semibold text-blue-800 mb-4 w-full break-words overflow-hidden text-center">{{ question }}</h2>

      <!-- Render radio buttons as styled buttons for single selection (type 'radio') -->
      <div v-if="type === 'radio'" class="flex flex-col items-center justify-center w-full">
        <div v-for="(option, index) in options" :key="index" class="my-2 w-full">
          <button 
            :class="{
              'border border-gray-300 p-2 w-full text-left rounded my-1': true,
              'bg-blue-500 text-white text-lg md:text-2xl': selectedOption === option,
              'bg-white text-gray-700 text-lg md:text-2xl': selectedOption !== option
            }"
            @click="selectOption(option)"
          >
            {{ option }}
          </button>
        </div>
      </div>

      <!-- Render dropdown for single selection (type 'dropdown' and not multiple) -->
      <div v-else-if="type === 'dropdown' && !multiple" class="my-4">
        <select v-model="selectedOption" class="p-2 border rounded w-full text-lg md:text-2xl">
          <option v-for="(option, index) in options" :key="index" :value="option">
            {{ option }}
          </option>
        </select>
      </div>

      <!-- Render checkboxes as styled buttons for multiple selection -->
      <div v-else-if="type === 'checkbox' && multiple && currentQuestion !== 5 && currentQuestion < 2">
        <div v-for="(option, index) in options" :key="index" class="my-2 w-full">
          <button 
            :class="{
              'border border-gray-300 p-2 w-full text-left rounded my-1': true,
              'bg-blue-500 text-white text-lg md:text-2xl': selectedOptions.includes(option),
              'bg-white text-gray-700 text-lg md:text-2xl': !selectedOptions.includes(option)
            }"
            @click="toggleOption(option)"
          >
            {{ option }}
          </button>
        </div>
      </div>

      <!-- Render hierarchical checkboxes based on currentQuestion -->
      <div v-else-if="type === 'checkbox' && multiple && currentQuestion === 2">
        <div v-for="(item, index) in options" :key="index" class="my-4">
          <h3 class="text-2xl md:text-4xl font-semibold text-blue-700">{{ item[0] }}</h3>
          
            <div v-for="(service, i) in item[1]" :key="i" class="my-2 w-full">
              <button 
                :class="{
                  'border border-gray-300 p-2 w-full text-left rounded my-1': true,
                  'bg-blue-500 text-white text-lg md:text-2xl': selectedOptions.some(opt => opt.item === item && opt.service === service),
                  'bg-white text-gray-700 text-lg md:text-2xl': !selectedOptions.some(opt => opt.item === item && opt.service === service)
                }"
                @click="toggleHierarchicalOption(item, service)"
              >
                {{ service }}
              </button>
            
          </div>
        </div>
      </div>

      <div v-else-if="type === 'checkbox' && multiple && currentQuestion === 3" class="flex flex-col items-center justify-center w-full">
        <div v-for="(sectorData, index) in options" :key="index" class="my-4 w-full max-w-3xl">
          <h3 class="text-2xl md:text-4xl font-semibold text-blue-700 text-center">{{ sectorData[0] }}</h3>
          <div v-for="(serviceData, i) in sectorData.slice(1)" :key="i" class="p-4 w-full flex flex-col items-center">
            <h4 class="text-lg md:text-3xl font-semibold text-blue-600 text-center">{{ serviceData[0] }}</h4>
            <div v-for="(subservice, j) in serviceData[1]" :key="j" class="my-2 w-full flex justify-center">
              <button 
                :class="{
                  'border border-gray-300 p-2 w-full max-w-2xl text-left rounded my-1': true,
                  'bg-blue-500 text-white text-lg md:text-2xl': selectedOptions.some(opt => opt.sectorData === sectorData && opt.serviceData === serviceData && opt.subservice === subservice),
                  'bg-white text-gray-700 text-lg md:text-2xl': !selectedOptions.some(opt => opt.sectorData === sectorData && opt.serviceData === serviceData && opt.subservice === subservice)
                }"
                @click="toggleSubOption(sectorData, serviceData, subservice)"
              >
                {{ subservice }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="type === 'checkbox' && multiple && currentQuestion === 4" class="flex flex-col items-center justify-center w-full">
        <div v-for="(sectorData, sectorIndex) in options" :key="'sector-' + sectorIndex" class="my-4 w-full max-w-3xl text-center">
          <h3 class="text-2xl md:text-4xl font-semibold text-blue-700">{{ sectorData[0] }}</h3>
          <div v-for="(serviceData, serviceIndex) in sectorData[1]" :key="'service-' + sectorIndex + '-' + serviceIndex" class="p-4 w-full flex flex-col items-center">
            <h4 class="text-lg md:text-3xl font-semibold text-blue-600">{{ serviceData[0] }}</h4>
            <div v-for="(subserviceData, subserviceIndex) in serviceData[1]" :key="'subservice-' + sectorIndex + '-' + serviceIndex + '-' + subserviceIndex" class="p-4 w-full flex flex-col items-center">
              <h5 class="text-base md:text-2xl font-semibold text-blue-500">{{ subserviceData[0] }}</h5>
              <div v-for="(plantcategory, plantcategoryIndex) in subserviceData[1]" :key="'plantcategory-' + sectorIndex + '-' + serviceIndex + '-' + subserviceIndex + '-' + plantcategoryIndex" class="my-2 w-full flex flex-col justify-center">
                <button 
                  :class="{
                    'border border-gray-300 p-2 w-full max-w-2xl text-left rounded my-1': true,
                    'bg-blue-500 text-white text-base md:text-xl': selectedOptions.some(opt => opt.sectorData === sectorData && opt.serviceData === serviceData && opt.subserviceData === subserviceData && opt.plantcategory === plantcategory),
                    'bg-white text-gray-700 text-base md:text-xl': !selectedOptions.some(opt => opt.sectorData === sectorData && opt.serviceData === serviceData && opt.subserviceData === subserviceData && opt.plantcategory === plantcategory)
                  }"
                  @click="togglePlantCategory(sectorData, serviceData, subserviceData, plantcategory)"
                >
                  {{ plantcategory[0] }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="type === 'checkbox' && multiple && currentQuestion === 5" class="flex flex-col items-center justify-center w-full">
        <div v-for="(sectorData, sectorIndex) in options" :key="'sector-' + sectorIndex" class="my-4 w-full max-w-3xl text-center">
          <h3 class="text-2xl md:text-4xl font-semibold text-blue-700">{{ sectorData[0] }}</h3>
          <div v-for="(serviceData, serviceIndex) in sectorData[1]" :key="'service-' + sectorIndex + '-' + serviceIndex" class="p-4 w-full flex flex-col items-center">
            <h4 class="text-lg md:text-3xl font-semibold text-blue-600">{{ serviceData[0] }}</h4>
            <div v-for="(subserviceData, subserviceIndex) in serviceData[1]" :key="'subservice-' + sectorIndex + '-' + serviceIndex + '-' + subserviceIndex" class="p-4 w-full flex flex-col items-center">
              <h5 class="text-base md:text-2xl font-semibold text-blue-500">{{ subserviceData[0] }}</h5>
              <div v-for="(plantcategoryData, plantcategoryIndex) in subserviceData[1]" :key="'plantcategory-' + sectorIndex + '-' + serviceIndex + '-' + subserviceIndex + '-' + plantcategoryIndex" class="p-4 w-full flex flex-col items-center">
                <h6 class="text-sm md:text-xl font-semibold text-blue-400">{{ plantcategoryData[0] }}</h6>
                <div v-for="(measuringcriteria, measuringcriteriaIndex) in plantcategoryData[1]" :key="'measuringcriteria-' + sectorIndex + '-' + serviceIndex + '-' + subserviceIndex + '-' + plantcategoryIndex + '-' + measuringcriteriaIndex" class="my-2 w-full flex flex-col items-center">
                  <div class="flex items-center justify-center w-full">
                    <input type="checkbox" :id="measuringcriteria" :value="{ sectorData, serviceData, subserviceData, plantcategoryData, measuringcriteria }" @change="updateSelectedOptions(sectorData, serviceData, subserviceData, plantcategoryData, measuringcriteria, $event)" class="mr-2 transform scale-150">
                    <label :for="measuringcriteria" class="text-gray-600 text-lg md:text-2xl">{{ measuringcriteria[0] }}</label>
                  </div>
                  <input type="text" @input="updateCriteriaValue(sectorData, serviceData, subserviceData, plantcategoryData, measuringcriteria, $event.target.value)" placeholder="Bitte Wert eingeben!" class="p-2 border rounded w-full mt-2 text-sm md:text-lg">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="quizcompleted === true" class="my-8">
        <form @submit.prevent="submitForm" class="space-y-4">
          <div>
            <label for="companyName" class="block text-gray-700 font-bold mb-2 text-lg md:text-2xl">Name des Unternehmens:</label>
            <input type="text" id="companyName" v-model="companyName"  class="p-2 border rounded w-full text-base md:text-xl" placeholder="Optional">
          </div>
          <div>
            <label for="firstName" class="block text-gray-700 font-bold mb-2 text-lg md:text-2xl">Vorname des Kontakts:</label>
            <input type="text" id="firstName" v-model="contactFirstName"  class="p-2 border rounded w-full text-base md:text-xl" placeholder="Optional">
          </div>
          <div>
            <label for="lastName" class="block text-gray-700 font-bold mb-2 text-lg md:text-2xl">Nachname des Kontakts:</label>
            <input type="text" id="lastName" v-model="contactLastName"  class="p-2 border rounded w-full text-base md:text-xl" placeholder="Optional">
          </div>
          <div>
            <label for="role" class="block text-gray-700 font-bold mb-2 text-lg md:text-2xl">Rolle des Kontakts:</label>
            <input type="text" id="role" v-model="contactRole"  class="p-2 border rounded w-full text-base md:text-xl" placeholder="Optional">
          </div>
          <div>
            <label for="email" class="block text-gray-700 font-bold mb-2 text-lg md:text-2xl">E-Mail des Kontakts:</label>
            <input type="email" id="email" v-model="contactEmail" required class="p-2 border rounded w-full text-base md:text-xl" placeholder="Pflichtfeld">
          </div>
          <div class="policy-container rounded flex flex-col">
            <div class="flex flex-col">
              <div class="flex items-center mr-2">
                <input type="checkbox" id="emailConsent" v-model="emailConsent" required class="mr-2 transform scale-150">
                <label for="emailConsent" class="text-gray-700 text-lg md:text-2xl ml-4">Ich stimme zu, per E-Mail kontaktiert zu werden, um den Kritis Check-Bericht und weiteres Marketingmaterial zu erhalten.</label>
              </div>
              <a href="/privacy-policy" class="text-blue-500 hover:text-blue-700 ml-6 text-sm md:text-base">Datenschutzrichtlinie</a>
            </div>       
            <div class="flex flex-col">
              <div class="flex items-center mr-2">
                <input type="checkbox" id="disclaimerConsent" v-model="disclaimerConsent" required class="mr-2 transform scale-150">
                <label for="disclaimerConsent" class="text-gray-700 text-lg md:text-2xl text-left ml-2 p-2">Ich bestätige, dass der Kritis Check nur zu Informationszwecken dient und rechtlich nicht bindend ist. Ich akzeptiere, dass keine Verantwortung für falsche Ergebnisse übernommen wird.</label>
              </div>
              <a href="/disclaimer" class="text-blue-500 hover:text-blue-700 text-sm md:text-base">Haftungsauschluss</a>
            </div>
          </div>
          <div>
            <label for="feedback" class="block text-gray-700 font-bold mb-2 text-lg md:text-2xl">Haben Sie Feedback über die Benutzererfahrung des Kritis Checks, das Sie mit uns teilen möchten?:</label>
            <input type="text" id="feedback" v-model="userFeedback" class="p-2 border rounded w-full text-base md:text-xl" placeholder="Optional">
          </div>
        </form>
      </div>

      <!-- Submit button  -->
      <button @click="submitAnswer" class="mt-4 p-2 bg-blue-500 text-white rounded hover:bg-blue-700 text-lg md:text-2xl">Absenden</button>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watchEffect, nextTick } from 'vue';

export default {
  props: ['question', 'options', 'type', 'multiple', 'additionalData', 'currentQuestion', 'selectedSectors', 'availableServices', 'plantcategoryData', 'quizcompleted'],
  setup(props, { emit }) {
    const state = reactive({
      selectedOptions: [],
      selectedOption: null,
      companyName: '',
      contactFirstName: '',
      contactLastName: '',
      contactRole: '',
      contactEmail: '',
      userFeedback: '',
      sectorDescription: '',
      emailConsent: false,
      disclaimerConsent: false
    });

    const selectOption = (option) => {
      state.selectedOption = option;
    };

    const toggleOption = (option) => {
      const index = state.selectedOptions.indexOf(option);
      if (index === -1) {
        state.selectedOptions.push(option);
      } else {
        state.selectedOptions.splice(index, 1);
      }
    };

    const toggleHierarchicalOption = (item, service) => {
      const option = { item, service };
      const index = state.selectedOptions.findIndex(opt => opt.item === item && opt.service === service);
      if (index === -1) {
        state.selectedOptions.push(option);
      } else {
        state.selectedOptions.splice(index, 1);
      }
    };

    const toggleSubOption = (sectorData, serviceData, subservice) => {
      const option = { sectorData, serviceData, subservice };
      const index = state.selectedOptions.findIndex(opt => opt.sectorData === sectorData && opt.serviceData === serviceData && opt.subservice === subservice);
      if (index === -1) {
        state.selectedOptions.push(option);
      } else {
        state.selectedOptions.splice(index, 1);
      }
    };

    const togglePlantCategory = (sectorData, serviceData, subserviceData, plantcategory) => {
      const option = { sectorData, serviceData, subserviceData, plantcategory };
      const index = state.selectedOptions.findIndex(opt => opt.sectorData === sectorData && opt.serviceData === serviceData && opt.subserviceData === subserviceData && opt.plantcategory === plantcategory);
      if (index === -1) {
        state.selectedOptions.push(option);
      } else {
        state.selectedOptions.splice(index, 1);
      }
    };

    watchEffect(() => {
      if (props.plantcategoryData && Array.isArray(props.plantcategoryData[1])) {
        state.selectedOptions = props.plantcategoryData[1].map(() => ({
          checkboxValue: false,
          criteriaValue: '',
        }));
      }
    });
    
    const updateSelectedOptions = (sectorData, serviceData, subserviceData, plantcategoryData, measuringcriteria, event) => {
      const value = { sectorData, serviceData, subserviceData, plantcategoryData, measuringcriteria };
      const index = state.selectedOptions.findIndex(option => option.sectorData === sectorData && option.serviceData === serviceData && option.subserviceData === subserviceData && option.plantcategoryData === plantcategoryData && option.measuringcriteria === measuringcriteria);
      if (event.target.checked) {
        if (index === -1) {
          state.selectedOptions.push({ ...value, checkboxValue: true, criteriaValue: '' });
        }
      } else {
        if (index !== -1) {
          state.selectedOptions.splice(index, 1);
        }
      }
    };

    const updateCriteriaValue = (sectorData, serviceData, subserviceData, plantcategoryData, measuringcriteria, text) => {
      const index = state.selectedOptions.findIndex(option => option.sectorData === sectorData && option.serviceData === serviceData && option.subserviceData === subserviceData && option.plantcategoryData === plantcategoryData && option.measuringcriteria === measuringcriteria);
      if (index !== -1) {
        state.selectedOptions[index].criteriaValue = text;
      }
    };

    const submitAnswer = async () => {
      if (props.multiple) {
        if (state.selectedOptions.length > 0) {
          const result = {
            answer: state.selectedOptions,
            sectorDescription: state.sectorDescription        
          };
          emit('answer', result);
          state.selectedOptions = []; // Reset selectedOptions
        } else {
          state.selectedOptions = []; // Reset selectedOptions
          const result = {
            answer: state.selectedOptions,
            sectorDescription: state.sectorDescription        
          };
          emit('answer', result);
        }
      } else {
        if (state.selectedOption !== null) {  
          const result = {
            answer: state.selectedOption,
            sectorDescription: state.sectorDescription
          };
          emit('answer', result);
          state.selectedOption = null; // Reset selectedOption
        } 
      }
      if (state.emailConsent && state.disclaimerConsent && props.quizcompleted === true) {
        const result = {
          companyName: state.companyName,
          contactFirstName: state.contactFirstName,
          contactLastName: state.contactLastName,
          contactRole: state.contactRole,
          contactEmail: state.contactEmail,
          emailConsent: state.emailConsent,
          disclaimerConsent: state.disclaimerConsent,
          userFeedback: state.userFeedback
        };
        emit('answer', result);
      } else if (props.quizcompleted === true){
        alert('Bitte füllen Sie alle Pflichtfelder aus und stimmen Sie den Bedingungen zu.');
      }
      // Scroll to top after updating question
      await nextTick();
      window.scrollTo(0, 0);
    };

    return {
      ...toRefs(state),
      submitAnswer,
      updateSelectedOptions,
      updateCriteriaValue,
      toggleOption,
      toggleHierarchicalOption,
      toggleSubOption,
      togglePlantCategory,
      selectOption
    };
  }
};
</script>

<style scoped>
.question-card {
  @apply p-4 bg-gray-100 rounded shadow-md my-6;
}
</style>
