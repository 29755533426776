<template>
  
    <div>
     
  
      <!-- Hero Abschnitt  -->
      <section class="bg-white py-16">
        <div class="container mx-auto text-center">
          <h1 class="lg:text-5xl text-2xl font-bold mb-4 lg:p-10">Überprüfen Sie in <u>Minuten</u>, ob Sie unter die Kritis-Verordnung fallen!</h1>
          <p class="lg:text-3xl text-xl mb-8 lg:p-8">Einfach, kostenlos und nachvollziehbar - Finden Sie heraus, ob die Kritis-Verordnung für Ihr Unternehmen gilt.</p>
          <!--button links to QUizview.vue -->
          <router-link to="/quiz" class="custom-hover bg-gradient-to-r from-blue-500 via-white to-blue-500 text-white py-3 px-6 rounded-lg text-2xl lg:text-4xl shadow-xl">Kritis Check jetzt starten!</router-link>
          
        </div>
      </section>
  
      <!-- Kernvorteile Abschnitt -->
      <section class="bg-gray-100 py-16">
        <div class="container mx-auto text-center">
          <h2 class="text-4xl font-bold mb-8">Warum Kritis Check?</h2>
          <div class="flex flex-col md:flex-row justify-around">
            <div class="mb-8 md:mb-0 md:w-1/3">
              <div class="bg-white p-6 rounded-lg shadow-lg">
                <h3 class="text-3xl font-bold mb-4">Einfach</h3>
                <p class="text-xl">Füllen sie einen einfachen Fragebogen aus, statt sich durch verwirrende Gesetzestexte zu kämpfen.</p>
              </div>
            </div>
            <div class="mb-8 md:mb-0 md:w-1/3">
              <div class="bg-white p-6 rounded-lg shadow-lg">
                <h3 class="text-3xl font-bold mb-4">Kostenlos</h3>
                <p class="text-xl">Bestimmen Sie, ob die Kritis Verordnung auf Ihr Unternehmen anwendbar ist, kostenlos.</p>
              </div>
            </div>
            <div class="md:w-1/3">
              <div class="bg-white p-6 rounded-lg shadow-lg">
                <h3 class="text-3xl font-bold mb-4">Nachvollziehbar</h3>
                <p class="text-xl">Erhalten Sie einen detaillierten Bericht, damit sie die Auswertung einfach nachprüfen können.</p>
              </div>
            </div>
            
          </div>
         
         
        </div>
       
      </section>
      <section class="bg-white py-16 lg:hidden">
        <router-link to="/quiz" class="custom-hover bg-gradient-to-r from-blue-500 via-white to-blue-500 text-white py-3 px-6 rounded-lg text-2xl lg:text-4xl shadow-xl">Kritis Check jetzt starten!</router-link>
    </section>
    
  
     
  
     
       

        
     
    </div>


  </template>
  
 
  
  <style scoped>
  /* Add this inside your <style> tag */
.custom-hover:hover {
  background-color: #3B82F6; /* This is the Tailwind color for blue-700 */
  background-image: none; /* This removes the gradient on hover */
}
</style>
  