<!-- src/components/Header.vue -->
<template>
  <header class="bg-gray-100 shadow-md p-4 m-0">
    <div class="container mx-auto flex flex-col md:flex-row items-center justify-between">
      <div class="logo flex items-center mb-4 md:mb-0">
        <router-link to="/startseite" class="flex items-center">
          <img src="../assets/Einfach-IT-Sicher-logo-only.png" alt="Kritis Check" class="h-16 mr-3">
          <div class="flex flex-col">
            <span class="text-4xl font-bold bg-gradient-to-r bg-clip-text text-transparent">Kritis Check</span>
          </div>
        </router-link>
      </div>
      <nav>
        <ul class="flex space-x-6">
          <li><a href="/startseite" class="text-blue-500  hover:text-blue-700 lg:text-2xl md:text-lg">Startseite</a></li>
          <li><a href="/impressum" class="text-blue-500 hover:text-blue-700 lg:text-2xl md:text-lg">Impressum</a></li>
          <li><a href="/kontakt" class="text-blue-500 hover:text-blue-700 lg:text-2xl md:text-lg">Kontakt</a></li>
          <li><a href="/newsletter" class="text-blue-500 hover:text-blue-700 lg:text-2xl md:text-lg">Newsletter</a></li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderComponent'
}
</script>

<style scoped>
/* Fügen Sie hier Ihre spezifischen Stile für die Header-Komponente hinzu */
</style>
